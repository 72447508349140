/* You can add global styles to this file, and also import other style files */

@import "./scss/variables.scss";
@import "./scss/main.scss";

$enable-cssgrid: true;
@import "../node_modules/bootstrap/scss/bootstrap.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: white;
}
