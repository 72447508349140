html {
  height: 100%;
}

body {
  font-family: 'KoHo' !important;
  background-color: $gray-900 !important;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}